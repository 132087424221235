@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --text-color: #fff;
  --bg-color: #002d62;
  --font--weight: 500;
}
textarea {
  padding: 6px;
}
input {
  padding-left: 4px;
}
.css-art2ul-ValueContainer2 {
  padding: 0px !important;
  padding-left: 10px !important ;
}
.css-1xc3v61-indicatorContainer {
  position: absolute !important;
  right: 0 !important;
}
.css-15lsz6c-indicatorContainer {
  padding: 0px !important;
}
