.container {
  max-width: 500px;
  margin: 0px 30px;
  width: 400px;
  padding: 15px 10px 10px 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.container .text {
  text-align: center;
  font-size: 41px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  background: white;
  -webkit-text-fill-color: transparent;
}
.container main {
  padding-bottom: 30px;
}
.container main .form-row {
  display: flex;
  margin: 32px 0;
}
.form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 10px 30px;
  position: relative;
}
.input-data input {
  padding-left: 10px;
  border-radius: 5px;
}
.form-row .textarea {
  height: 70px;
}
.input-data input,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.input-data input:focus ~ label,
.textarea textarea:focus ~ label,
.input-data input:valid ~ label,
.textarea textarea:valid ~ label {
  transform: translateY(-35px);
  font-size: 16px;
  color: #fff;
}

.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  padding-left: 5px;
}
.textarea label {
  width: 100%;
  bottom: 40px;
  background: #fff;
}
.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before {
  transform: scale(1);
}
@media (max-width: 700px) {
  .container .text {
    font-size: 30px;
  }

  .container main .form-row {
    display: block;
  }
  .form-row .input-data {
    margin: 35px 0 !important;
  }
  .image_forbg {
    border-radius: 10px;
    background-color: rgba(12, 3, 22, 0.9);
  }
}
.background-image-container {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.1);
}
.image_forbg {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}
.content {
  position: relative;
  z-index: 1;
}
.cursor__pointer {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 88%;
  font-size: 18px;
  padding: 10px;
  padding-right: 20px;
  display: inline;
}
