.cursor__video__list {
  cursor: pointer;
}
.cursor__video__list:hover {
  background-color: rgb(242, 241, 245);
}
.blocked-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity or color as needed */
  z-index: 9999; /* Adjust the z-index to make sure it's on top */
}
.video__brief > aside {
  display: flex;
}
.video__brief > aside > p {
  width: 150px;
}
.video__brief > aside > h1 {
  font-weight: 500;
  color: rgb(49, 6, 90);
}

@media only screen and (max-width: 750px) {
  .video__brief > aside {
    display: block;
  }
  .video__brief > aside > p {
    width: 100%;
  }
}
