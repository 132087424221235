.advertisea__main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.carousel {
  margin: 0px 0px;
  display: flex;
  height: 90vh;
  width: 98%;

  /* max-width: 800px; */
}

.carousel_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel_card {
  display: flex;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0px;
  -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  /* transform: scale(0.8); */
  transition: 0.5s ease-in-out;
}

.carousel_card-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: visible;
}

.card_image {
  width: 100%;
  object-fit: fill;
}

.card_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  align-items: flex-start;
  justify-content: flex-end;
}

.card_title {
  color: white;
  background-color: rgb(18, 17, 17);
  padding: 10px 20px;
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0.8);
}
.card_disc {
  color: white;
  background-color: rgb(18, 17, 17);
  padding: 10px 20px;
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0.8);
}
.carousel_arrow_left,
.carousel_arrow_right {
  position: absolute;
  font-size: 40px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-bottom: 7px;
  cursor: pointer;
}

.carousel_arrow_left {
  left: 15px;
}

.carousel_arrow_right {
  right: 15px;
}

.carousel_pagination {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}

.pagination_dot {
  height: 10px;
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.pagination_dot:hover {
  transform: scale(1.2);
}

.pagination_dot-active {
  background-color: steelblue;
}
@media (max-width: 800px) {
  .carousel {
    height: 50vh;
  }
}
