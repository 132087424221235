.main__admin > div {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 7px 5px;
  margin: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.main__admin {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.table__video__app {
  overflow: auto;
  margin-top: 20px;
  text-transform: uppercase;
}
.main__admin input {
  width: 300px;
  height: 35px;
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
  padding-left: 5px;
}
.custom-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-collapse: collapse;
}
.header-cell {
  border-radius: 4px;
}
.header-cell td {
  background-color: #2f2f5a;
  color: white;
  padding: 10px;
  border: 1px solid #2f2f5a;
}

.table-row {
  height: 32px;
  margin: 5px 0;
  padding: 10px;
  border-bottom: 0.3px solid #2f2f5a; /* Add bottom border between rows */
}

.custom-table td {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding: 4px 16px 4px 40px;
}
.custom-table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
  border-radius: 5px; /* Apply border-radius to the table */
}

.custom-table td,
.custom-table th {
  padding: 5px 20px;
  border-top: 1px solid gray;
}

.custom-table tr:first-child th {
  border-top: none;
}

.custom-table tr:last-child td {
  border-bottom: none;
}

.searchhhhhh {
  display: flex;
  margin-left: 30px;

  margin: 10px 0px;
  gap: 10px;
  font-size: 18px;
}
.searchhhhhh input {
  width: 300px;
  padding-right: 20px;
  border: 1px solid;
  border-radius: 5px;
}
.search__icon {
  position: relative;
  right: 30px;
  background-color: #fff;
  height: 22px;
  top: 3px;
}
.button_style {
  padding: 3px 25px 3px 25px;
  background-color: #2f2f5a;
  color: white;
  width: "110px";
  border-radius: 3px;
  height: fit-content;
}
.header__of__main h1 {
  color: var(--bg-color);
  display: inline-block;
  font-family: josefin sans;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  margin-left: 20px;
  padding-bottom: 5px;
  position: relative;
  text-transform: uppercase;
}
.main__admin div h1 {
  width: 190px;
  font-size: 18px;
}
.divission__ {
  display: block;
}
.aside__new__garage > div:first-child > p {
  width: 200px;
}
.aside__new__garage > div > div > input {
  height: 35px;
  width: 300px;
}
.aside__new__garage > div > div > select {
  height: 35px;
  width: 300px;
}
.aside__new__garage > div label {
  font-weight: 500;
}
.aside__new__garage {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.main__admin div textarea {
  width: 400px;
  min-height: 80px;
  max-height: 80px;
  margin-left: 5px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 16px;
}
.main__admin div b {
  width: 200px;
  text-align: center;
}
.alignleft {
  text-align: right;
}

.header__of__main h1 {
  color: var(--bg-color);
  display: inline-block;
  font-family: "josefin sans"; /* Make sure the font name is enclosed in single quotes */
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  margin-left: 20px;
  padding-bottom: 5px;
  position: relative;
  text-transform: uppercase;
}
.header__of__main h1:after {
  bottom: 0; /* Adjust the position of the underline as needed */
  height: 2px; /* Adjust the thickness of the underline as needed */
  width: 100%; /* Underline the full width of the parent element */
  content: "";
  background-color: var(--bg-color); /* Use the desired background color */
  position: absolute;
  left: 0;
}
.table_1 tr td {
  border-bottom: 1px solid gray;
  font-size: medium;
  padding: 10px 13px;
  text-align: left;
}
.save_btn {
  text-align: right;
  margin-right: 20px;
  display: flex;
  gap: 5px;
}
.header__of__main {
  margin-bottom: 5px;
}
.header__of__main h1 {
  display: inline-block;
  font-family: "josefin sans";
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  color: var(--bg-color);
  text-transform: uppercase;
}
.header__of__main h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: var(--bg-color);
}
.header__of__main h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;

  background-color: var(--bg-color);
}
.button_style {
  padding: 3px 25px 3px 25px;
  background-color: #2f2f5a;
  color: white;
  border-radius: 3px;
  height: fit-content;
}
.header__of__main h1 {
  color: var(--bg-color);
  display: inline-block;
  font-family: josefin sans;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  margin-left: 20px;
  padding-bottom: 5px;
  position: relative;
  text-transform: uppercase;
}
.main__admin div h1 {
  width: 310px;
  font-size: 18px;
}
.divission__ {
  display: block;
}
.aside__new__garage > div:first-child > p {
  width: 200px;
}
.aside__new__garage > div > div > input {
  height: 35px;
  width: 300px;
}
.aside__new__garage > div > div > select {
  height: 35px;
  width: 300px;
}
.aside__new__garage > div label {
  font-weight: 500;
}
.aside__new__garage {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.main__admin div textarea {
  width: 640px;
  min-height: 80px;
  max-height: 80px;
  margin: 0px 5px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 16px;
}
.main__admin div b {
  width: 200px;
  text-align: center;
}
.alignleft {
  text-align: right;
}

.header__of__main h1 {
  color: var(--bg-color);
  display: inline-block;
  font-family: "josefin sans"; /* Make sure the font name is enclosed in single quotes */
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  margin-left: 20px;
  padding-bottom: 5px;
  position: relative;
  text-transform: uppercase;
}
.header__of__main h1:after {
  bottom: 0; /* guuo*/
  height: 2px; /* Adjust the thickness of the underline as needed */
  width: 100%; /* Underline the full width of the parent element */
  content: "";
  background-color: var(--bg-color); /* Use the desired background color */
  position: absolute;
  left: 0;
}
.table_1 tr td {
  border-bottom: 1px solid gray;
  font-size: medium;
  padding: 10px 13px;
  text-align: left;
}
.save_btn {
  text-align: right;
  margin-right: 20px;
  display: flex;
  gap: 5px;
}
.header__of__main {
  margin-bottom: 5px;
}
.header__of__main h1 {
  display: inline-block;
  font-family: "josefin sans";
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  color: var(--bg-color);
  text-transform: uppercase;
  margin-left: 0px;
}
.header__of__main h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: var(--bg-color);
}
.header__of__main h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;

  background-color: var(--bg-color);
}
@media (max-width: 900px) {
  .main__admin > div {
    width: 100%;
  }
  .main__admin > div > div {
    width: 100% !important;
    margin: 0px 3px !important;
  }
  .main__admin input {
    width: 100%;
    margin: 0px 3px;
  }
  .main__admin div textarea {
    width: 100%;
    margin: 0px;
  }
  .header__of__main {
    display: block;
  }
  .save_btn {
    margin-left: 0px;
    margin: 0px;
  }
  .table__video__app {
    padding: 10px;
  }

  .searchhhhhh {
    display: flex;
    margin-left: 0px;
    margin: 10px 0px;
    gap: 2px;
    font-size: 18px;
    width: 100%;
  }
  .searchhhhhh > p {
    width: 70px;
  }
  .searchhhhhh input {
    width: 275px;
  }
  .main__admin div h1 {
    margin-top: 0px;
  }
  .button_style {
    margin: 5px;
  }
  .all__page__main__section > aside:first-child {
    display: none;
  }
  .all__page__main__section > aside:last-child {
    width: 100%;
    padding: 0px 10px;
  }
}
.css-art2ul-ValueContainer2 {
  padding: 0px !important;
  padding-left: 10px !important ;
}
.css-1xc3v61-indicatorContainer {
  font-size: small !important;
  position: absolute !important;
  right: 0 !important;
  padding-left: 5px !important;
}
.css-1fdsijx-ValueContainer {
  padding: 0px !important;
  font-size: small !important;
  padding-left: 5px !important;
}
.css-qbdosj-Input {
  height: 28px !important;
  padding: 0px !important;
  padding-left: 5px !important;
}
