.all__module {
  display: flex;
  gap: 10px;
  margin: 0px 20px;
}
.all__module > h1 {
  padding: 10px 20px;
  background-color: aliceblue;

  border-radius: 5px;
  cursor: pointer;
}
.all__module > .active {
  background-color: rgb(230, 209, 234);
  font-weight: bold;
  text-decoration: underline;
  border-radius: 5px;
  cursor: pointer;
}
.all__module > .active:hover {
  background-color: rgb(230, 209, 234);
}
.all__module > h1:hover {
  background-color: rgb(214, 229, 241);

  border-radius: 5px;
  cursor: pointer;
}
.last___Lihover:hover {
  text-decoration: none !important;
}
@media (max-width: 900px) {
  .all__module {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    margin: 0px 5px;
  }
  .textmax__width {
    min-width: 100%;
    max-width: 100%;
  }
  .video__dashboard {
    display: block;
  }
  .video__dashboard > div {
    width: 100%;
  }
  .all__module > h1 {
    padding: 5px 5px;
  }
  .all__module > .active {
    background-color: rgb(230, 209, 234);
    font-weight: 400;
    text-decoration: underline;
    border-radius: 5px;
    cursor: pointer;
  }
  .sidebar {
    height: 75vh;
  }
}
